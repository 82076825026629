<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- ************************** OVERVIEW ******************************** -->
        <section id="overview">
          <div>
            <i18n path="overview.text" tag="div" class="bsat__paragraph">
              <template #link1>
                <a @click="goToTab(3)">{{ $t("overview.link1") }}</a>
              </template>
            </i18n>
          </div>
        </section>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>
        <!-- ********************** RECOMMENDED RESOURCES ************************** -->

        <div class="bsat__subtitle mb-4">
          {{ $t("recommendedResources.title") }}
        </div>

        <div class="bsat__paragraph mt-n3 mb-6">
          {{ $t("recommendedResourcesIntro") }}
        </div>

        <ul class="ml-0 ml-sm-4 bsat__ul">
          <li>
            <div class="bsat__paragraph">
              {{ $t("recommendedResources.paragraph") }}
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.paragraph2.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(1)">{{
                    $t("recommendedResources.paragraph2.link")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li>

          <!-- <li>
            <div>
              <i18n
                path="recommendedResources.paragraph3.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(1)">{{
                    $t("recommendedResources.paragraph3.link")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li> -->

          <li>
            <div>
              <i18n
                path="recommendedResources.paragraph4.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(2)">{{
                    $t("recommendedResources.paragraph4.link")
                  }}</a>
                </template>
              </i18n>
            </div>
          </li>

          <li>
            <div class="bsat__paragraph">
              {{ $t("recommendedResources.paragraph5") }}
            </div>
          </li>
        </ul>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "Feedback rounds (as many as necessary) with decision-level of Interministerial/interdepartmental group and advisory group. ",
          "Analysis of collected information during update of strategy and roadmap."
        ]
      },
      "task": {
        "text": "{title}: Draft updated bioeconomy strategy and updated roadmap ",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Drafting group: designated technical expert from involved ministries or governmental departments ",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "link1": "Step 1, Phase 3",
      "text": "The Bioeconomy strategy might contain the roadmap to implement measures. In some cases, they are two different and independent documents, on the one hand the bioeconomy strategy as such, and secondly, the roadmap to implement it. As the case may be, the updated regional bioeconomy strategy and roadmap will be drafted by the designated group ({link1}) and supported/enriched  somany  times as seem necessary by the decision level of the Interministerial group. The final version of the strategy and roadmap should go over several rounds of feedback internally (within members of the strategy update working group), before going through a final consultation process."
    },
    "recommendedResources": {
      "paragraph": "Current regional bioeconomy strategy vision ",
      "paragraph2": {
        "link": "Step 1",
        "text": "Updated priority areas and goals({link})"
      },
      "paragraph3": {
        "link": "Step 1",
        "text": "Updated roadmap({link})"
      },
      "paragraph4": {
        "link": "Step 2",
        "text": "Assigned policy and financial mechanisms for the specific planned actions in the roadmap({link})"
      },
      "paragraph5": "Feedback from participatory approach. ",
      "title": "Recommended resources "
    },
    "title": "Step 3: Draft of updated bioeconomy strategy and updated roadmap",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import ReadMore from "@/components/ReadMore";

export default {
  name: "UpdatePhase4Step3",
  components: {
    ReadMore
  },
  data: () => ({
    zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
  }),
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("recommendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
